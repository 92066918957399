import React, { useState } from 'react';

const OrderFormModal = ({ closeModal, submitOrder }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    notes: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.phoneNumber) {
      alert('Please fill out the required fields: Name and Phone Number.');
      return;
    }
    await submitOrder(formData);
    setSubmitted(true);
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        if (e.target.className.includes('modal-overlay')) closeModal();
      }}
    >
      <div className="modal-content">
        {submitted ? (
          <div className="success-message">
            <p>Many thanks for your order, it will be ready to pick up the next working day in our shop.</p>
          </div>
        ) : (
          <form className="order-form" onSubmit={handleSubmit}>
            <h2 className="form-title">Hey there!</h2>
            <div className="form-text">
              Kindly provide your name, phone number, and any additional notes you'd like to include with your order. Your order will be ready for pickup at <a className="link" href="https://g.co/kgs/ADtTnqu" target="_blank" rel="noopener noreferrer">Gastein Moonshine Townhouse</a> on the next working day, Monday to Friday, between 15:00 and 18:00. We look forward to seeing you!
            </div>
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name <span className="required">*</span></label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="phoneNumber" className="form-label">Phone Number <span className="required">*</span></label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                className="form-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="notes" className="form-label">Optional Notes</label>
              <input
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                className="form-textarea"
              />
            </div>
            <button
              type="button"
              className="cancel"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button type="submit" className="submit-button">ORDER NOW</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default OrderFormModal;
