import React, { useState, useEffect } from 'react';
import Controls from './Controls';
import Preview from './Preview';
import OrderFormModal from './OrderFormModal';
import { getColors, getValidGraphicColors } from './utils';
import { stockData } from '../data/stockData';

const ProductList = () => {
  const [productType, setProductType] = useState('hoodies'); // Default to hoodies
  const [productColor, setProductColor] = useState('black'); // Default color
  const [productSize, setProductSize] = useState('M'); // Default size
  const [graphicType, setGraphicType] = useState('Badgastein'); // Default graphic
  const [graphicColor, setGraphicColor] = useState('black'); // Default graphic color
  const [showModal, setShowModal] = useState(false); // Modal state

  const colors = getColors(productType);

  // Get available sizes for the current product and color
  const availableSizes = stockData[productType]?.[productColor] || {};

  // Ensure the selected color is valid when switching product types
  useEffect(() => {
    if (!colors.includes(productColor)) {
      setProductColor(colors[0]); // Fallback to the first available color
    }
  }, [productType, colors, productColor]);

  const validGraphicColors = getValidGraphicColors(graphicType);

  // Ensure the selected graphic color is valid
  useEffect(() => {
    if (!validGraphicColors.includes(graphicColor)) {
      setGraphicColor(validGraphicColors[0]); // Fallback to the first valid color
    }
  }, [graphicType, validGraphicColors, graphicColor]);

  const submitOrder = async (formData) => {
    const taskName = `${productType}, ${productColor}, ${productSize}, ${graphicType}, ${graphicColor} (${formData.name})`;
    const orderDetails = {
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      notes: formData.notes,
    };

    const asanaApiToken = '2/795176665721855/1209131552915386:a2b8126f9588cc9e626de6f82503e6ac'; // Replace with your token
    const asanaWorkspaceId = '795178220666384'; // Replace with your workspace ID
    const asanaProjectId = '796643286640221'; // Replace with your project ID

    try {
      const response = await fetch('https://app.asana.com/api/1.0/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${asanaApiToken}`,
        },
        body: JSON.stringify({
          data: {
            name: taskName,
            notes: `
              Customer Name: ${orderDetails.name}
              Phone Number: ${orderDetails.phoneNumber}
              Notes: ${orderDetails.notes}
            `,
            projects: [asanaProjectId],
            workspace: asanaWorkspaceId,
          },
        }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Order submitted successfully!');
        setShowModal(false); // Close the modal on success
      } else {
        console.error('Failed to create Asana task:', result);
        alert('Failed to submit the order. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      alert('Error submitting the order. Please try again.');
    }
  };

  return (
    <div className="main-container">
      <Preview
        productType={productType}
        productColor={productColor}
        graphicType={graphicType}
        graphicColor={graphicColor}
      />
      <button
        className="open-order-button"
        onClick={() => setShowModal(true)}
      >
        ORDER NOW
      </button>
      <Controls
        productType={productType}
        setProductType={setProductType}
        productColor={productColor}
        setProductColor={setProductColor}
        productSize={productSize}
        setProductSize={setProductSize}
        graphicType={graphicType}
        setGraphicType={setGraphicType}
        graphicColor={graphicColor}
        setGraphicColor={setGraphicColor}
        colors={colors}
        availableSizes={availableSizes} // Pass available sizes to Controls
      />
      <div className="graphic-controls-container">
        <h2>Graphic</h2>
        <div className="graphic-controls">
          {[...new Set([
            'Badgastein', 'Compass', 'Dweller',
            'Paradise', 'Schareck', 'Spirit', 'Sporty', 'Waterfall',
            'Authentic', 'Deer', 'Grizzly', 'Pinetree', 'Deer2',
            'Pow', 'Solid', 'Summit', 'Visions',
          ])].map((graphic) => (
            <button
              key={graphic}
              className={`graphic-button ${graphicType === graphic ? 'active' : ''}`}
              onClick={() => {
                setGraphicType(graphic);
                const validColors = validGraphicColors;
                if (!validColors.includes(graphicColor)) {
                  setGraphicColor(validColors[0]);
                }
              }}
            >
              {graphic}
            </button>
          ))}
        </div>
        <h2>Graphic Color</h2>
        <div className="graphic-color-controls">
          {validGraphicColors.map((color) => (
            <button
              key={color}
              className={`graphic-color-button ${graphicColor === color ? 'active' : ''}`}
              onClick={() => setGraphicColor(color)}
            >
              {color.charAt(0).toUpperCase() + color.slice(1)}
            </button>
          ))}
        </div>
      </div>
      {showModal && (
        <OrderFormModal
          closeModal={() => setShowModal(false)}
          submitOrder={submitOrder}
        />
      )}
    </div>
  );
};

export default ProductList;
