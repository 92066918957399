import React from 'react';

const Preview = ({ productType, productColor, graphicType, graphicColor }) => {
  return (
    <div
      className="preview-container"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100%',
        position: 'relative',
      }}
    >
      {/* Product Overlay */}
      <div className="product-overlay">
        <img
          src={`/images/products/${productType}/${productColor}_${productType.slice(0, -1)}.png`}
          alt={`${productColor} ${productType}`}
        />
        <img
          src={`/images/graphics/${graphicColor}_graphics/${graphicType}.png`}
          alt={`${graphicType} graphic in ${graphicColor}`}
          className="graphic-overlay"
        />
        {/* Conditionally Render cordoverlay.png */}
        {productType === 'hoodies' && (
          <img
            src={`/images/backgrounds/cordoverlay.png`}
            className="graphic-overlay"
            alt="Cord Overlay"
          />
        )}
      </div>
    </div>
  );
};

export default Preview;
