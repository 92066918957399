export const getColors = (productType) => {
  const hoodieColors = [
    'black', 'red', 'blue', 'beige', 'olive',
    'darkgrey', 'skyblue', 'mintgreen', 'yellow', 'offwhite',
  ];
  const tshirtColors = ['black', 'blue', 'darkgrey', 'olive', 'red', 'white', 'brown', 'offwhite', 'beige'];

  return productType === 'hoodies' ? hoodieColors : tshirtColors;
};

export const getValidGraphicColors = (graphicType) => {
  const blackGraphics = [
    'Badgastein', 'Compass', 'Deer2', 'Dweller', 'Fairytale',
    'National', 'Paradise', 'Schareck', 'Spirit', 'Sporty', 'Waterfall',
  ];

  const whiteGraphics = [
    'Authentic', 'Badgastein', 'Compass', 'Deer', 'Dweller', 'Grizzly',
    'Lunatic', 'Mother', 'National', 'Paradise', 'Pinetree', 'Pow',
    'Schareck', 'Solid', 'Spirit', 'Sporty', 'Summit', 'Trojan', 'Visions', 'Waterfall',
  ];

  const validGraphicColors = [];
  if (blackGraphics.includes(graphicType)) validGraphicColors.push('black');
  if (whiteGraphics.includes(graphicType)) validGraphicColors.push('white');

  return validGraphicColors;
};
