export const stockData = {
  hoodies: {
    black: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    red: {
      XS: 10,
      S: 10,
      M: 10,
      L: 0,
      XL: 10,
      "2XL": 10,
    },
    blue: {
      XS: 10,
      S: 10,
      M: 0,
      L: 0,
      XL: 0,
      "2XL": 10,
    },
    beige: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    olive: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    darkgrey: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 0,
      "2XL": 10,
    },
    grey: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 0,
      "2XL": 10,
    },
    skyblue: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    mintgreen: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    yellow: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    offwhite: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
  },
  tshirts: {
    black: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    red: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    brown: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    blue: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    olive: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    darkgrey: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    white: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    offwhite: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
    beige: {
      XS: 10,
      S: 10,
      M: 10,
      L: 10,
      XL: 10,
      "2XL": 10,
    },
  },
};
