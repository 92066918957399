import React from 'react';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/products');
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Your Journey Begins Here</h1>
        <p>Experience modern simplicity with Townhouse.</p>
        <button className="hero-button" onClick={handleGetStarted}>
          Get Started
        </button>
      </div>
    </section>
  );
};

export default Hero;
