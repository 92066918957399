import React from 'react';
import { stockData } from "../data/stockData";

const Controls = ({
  productType,
  setProductType,
  productColor,
  setProductColor,
  productSize,
  setProductSize,
  graphicType,
  setGraphicType,
  graphicColor,
  setGraphicColor,
  colors = [], // Default to an empty array
  validGraphicColors = [], // Default to an empty array
}) => {
  // Handle Color Change with Size Validation
  const handleColorChange = (color) => {
    setProductColor(color);

    // Check if the currently selected size is available for the new color
    const sizesForColor = stockData[productType]?.[color] || {};
    if (!sizesForColor[productSize]) {
      // Find the first available size for the new color
      const firstAvailableSize = Object.keys(sizesForColor).find(
        (size) => sizesForColor[size] > 0
      );

      // Update the size to the first available size or null if none
      setProductSize(firstAvailableSize || "");
    }
  };

  return (
    <div className="controls">
      {/* Product Type Controls */}
      <h2>Product</h2>
      <div className="product-controls">
        <button
          onClick={() => setProductType("hoodies")}
          className={productType === "hoodies" ? "active" : ""}
        >
          Hoodies
        </button>
        <button
          onClick={() => setProductType("tshirts")}
          className={productType === "tshirts" ? "active" : ""}
        >
          T-Shirts
        </button>
      </div>

      {/* Color Controls */}
      <h2>Color</h2>
      <div className="color-controls">
        {colors &&
          colors.map((color) => (
            <button
              key={color}
              className={`color-button ${color} ${
                productColor === color ? "active" : ""
              }`}
              onClick={() => handleColorChange(color)}
            >
              {color}
            </button>
          ))}
      </div>

      {/* Size Controls */}
      <h2>Size</h2>
      <div className="size-controls">
        {["XS", "S", "M", "L", "XL", "2XL"].map((size) => (
          <button
            key={size}
            className={`size-button ${productSize === size ? "active" : ""}`}
            onClick={() => setProductSize(size)}
            disabled={!(stockData[productType]?.[productColor]?.[size] > 0)} // Disable button if size is unavailable for the selected color
            style={{
              opacity: stockData[productType]?.[productColor]?.[size] > 0 ? 1 : 0.5,
              cursor: stockData[productType]?.[productColor]?.[size] > 0 ? "pointer" : "not-allowed",
            }}
          >
            {size}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Controls;
